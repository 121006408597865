import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-10" }
const _hoisted_2 = { class: "text-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ConnexionLayout = _resolveComponent("ConnexionLayout")!

  return (_openBlock(), _createBlock(_component_ConnexionLayout, {
    done: _ctx.done,
    errorMessage: _ctx.errorMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valid) = $event)),
        ref: "form",
        autocomplete: "on",
        "validate-on": "blur",
        class: "mt-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.nameInput,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameInput) = $event)),
                label: _ctx.$t("account_name"),
                type: "name",
                autocomplete: "given-name",
                class: "mb-1",
                rounded: "",
                flat: "",
                variant: "solo",
                "bg-color": "surface-light",
                color: "blue-grey-darken-4",
                "base-color": "blue-grey-darken-4",
                rules: _ctx.nameRule()
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.emailInput,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailInput) = $event)),
                label: _ctx.$t("account_email"),
                type: "email",
                class: "mb-1",
                rounded: "",
                flat: "",
                variant: "solo",
                "bg-color": "surface-light",
                color: "blue-grey-darken-4",
                "base-color": "blue-grey-darken-4",
                autocomplete: "email",
                rules: _ctx.emailRules()
              }, {
                "prepend-inner": _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi-email-outline",
                    color: "primary"
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.passwordInput,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordInput) = $event)),
                class: "mb-1",
                label: _ctx.$t("account_password"),
                type: "password",
                rounded: "",
                flat: "",
                variant: "solo",
                "bg-color": "surface-light",
                color: "blue-grey-darken-4",
                "base-color": "blue-grey-darken-4",
                autocomplete: "current-password",
                rules: _ctx.passwordRules()
              }, {
                "prepend-inner": _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi-lock-outline",
                    color: "primary"
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_btn, {
                variant: "flat",
                block: "",
                onClick: _ctx.submit,
                color: "primary",
                disabled: _ctx.isLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('sign_up')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('account_have_account')) + " ", 1),
          _createVNode(_component_router_link, {
            to: { name: 'login', query: this.$route.query },
            class: "link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('log_in')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["done", "errorMessage"]))
}