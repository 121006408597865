<template>
  <v-menu v-model="showDialog">
    <template v-slot:activator="{ props }">
      <v-card variant="outlined" align="center" class="pt-1 pb-1 pl-2 pr-2 rounded-xl" v-if="userName" v-bind="props"
        id="account_card">
        <v-avatar color="primary">
          <span class="text-h5 text-capitalize">{{ initial }}</span>
        </v-avatar>
        <span class="text-capitalize pl-1 text-subtitle-1"> {{ userName }}</span>
      </v-card>
    </template>
    <v-sheet class="pt-2 pb-2 bg-surface-variant">
      <v-btn variant="text" block align="left" class="text-left bg-surface-variant" :to="{ name: 'all-orders', params: allOrdersParam }">{{ $t('my_oders') }}</v-btn>
      <v-btn variant="text" block align="left" class="text-left bg-surface-variant" @click="logout">{{ $t('logout') }}</v-btn>
    </v-sheet>
  </v-menu>
</template>

<script lang='ts'>
import { userRepository } from '@/data/UserRepository'
import { Options, Vue } from 'vue-class-component'
import { RouteParamsRaw, useRoute } from 'vue-router'

@Options({})
export default class AccountAccessView extends Vue {
  showDialog = false
  get userName(): string | null {
    return this.$store.state.activeUserName
  }

  get initial(): string | null {
    if (!this.$store.state.activeUserName) {
      return ''
    }
    return this.$store.state.activeUserName.substring(0, 1)
  }

  get allOrdersParam(): RouteParamsRaw {
    const route = useRoute()
    return {
      slug: route.params.slug,
    }
  }

  mounted(): void {
    this.$store.dispatch('fetchUserName')
  }

  logout(): void {
    this.showDialog = false
    userRepository.logout()
    this.$store.dispatch('fetchUserName')
  }
}
</script>
