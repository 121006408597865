<template>
  <v-app-bar dark flat color="blue-grey-darken-4" align="left" v-if="business" class="pr-2">
    <template v-slot:prepend>
      <v-btn icon="mdi-home" @click="goToHome"></v-btn>
    </template>
    <v-toolbar-title class="font-weight-black">{{ business.title }}</v-toolbar-title>
    <AccountAccessView />
  </v-app-bar>

  <v-main class="text-left">
    <div v-for="order in orders" :key="order.id" class="pl-4 pr-4">
      <v-row lign="center" justify="center" class="fill-width">
        <router-link :to="{ name: 'order', params: { order_id: order.uuid } }" v-slot="{ navigate }" custom>
          <v-card class="pa-4 ma-4 rounded-xl" width="700" @click="navigate">
            <p class="text-left">
              <span v-html="orderStatus(order)"></span>
            </p>
            <v-col cols="12" color="black">
              <v-row v-for='item in order.cart' :key='item.itemId' class='pt-1 pb-1 pl-0 pr-0' justify="space-between">
                <v-col class='pa-0 ma-0 text-left'>
                  <span>{{ item.count }}x </span>
                  <span>{{ item.title }}</span>
                </v-col>
                <v-col class='pa-0 ma-0 text-right'>
                  {{ item.totalPrice }}
                </v-col>
              </v-row>
              <v-row class='pb-1 font-weight-bold mt-3' justify="space-between">
                <v-col class='pa-0 ma-0 text-left'>{{ $t('total') }}</v-col>
                <v-col class='pa-0 ma-0 text-right strong'>{{ order.totalPrice }}</v-col>
              </v-row>
            </v-col>
            <p class="text-caption text-disabled">
              {{ formattedOrderDate(order) }}
            </p>
          </v-card>
        </router-link>
      </v-row>
    </div>
  </v-main>
</template>

<script lang='ts'>
import AccountAccessView from '@/components/AccountAccessView.vue'
import { formatDateTime } from '@/formatter/DateFormatter'
import { BusinessModel } from '@/model/Menu'
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'

@Options({
  components: { AccountAccessView }
})
export default class AllOrdersView extends Vue {
  get orders(): Order[] {
    return this.$store.state.allOrders ?? []
  }

  get business(): BusinessModel | null {
    return this.$store.state.menu?.business
  }

  goToHome() {
    const slug = this.$store.state.menu?.business?.slug
    this.$router.push({ name: 'home', params: { slug: slug } })
  }

  mounted(): void {
    const route = useRoute()
    const slug = route.params['slug']
    this.$store.dispatch('fetchAllOrders', slug)
    if (!this.business) {
      this.$store.dispatch('fetchMenu', slug)
    }
  }

  formattedOrderDate(order: Order): string | null {
    return formatDateTime(order.createdAt)
  }

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
</script>
