<template>
  <v-sheet>
    <slot></slot>
    <v-sheet class="pt-5 pb-5 pl-3 pr-3">
      <v-col>
        <div v-if="cart.isEmpty()" align="center">
          <v-icon icon="mdi-basket-outline" size="x-large" color="grey-darken-1"></v-icon>
          <p class="text-grey-darken-1 pt-2">{{ $t('cart_empty_message') }}</p>
        </div>
        <v-row v-for="item in cartItems()" :key="item.cartItem.itemId" v-else class="pt-1 pb-1 pl-0 pr-0">
          <v-col class="pa-0 ma-0 me-auto" cols="auto">
            <v-col class="text-left text-subtitle-1 font-weight-medium pa-0 ma-0 me-auto" cols="auto">
              {{ item.label }}
            </v-col>
          </v-col>
          <v-col class="pa-0 ma-0" cols="auto">
            <v-col class="pa-0 ma-0" cols="auto">
              <v-btn density="compact" variant="plain" icon="mdi-minus-circle-outline" class="z-index fab raised"
                @click="onMinusFromCart(item.cartItem.itemId)" />
              <span class="text-body-2">{{ item.cartItem.count }}</span>
              <v-btn density="compact" variant="plain" icon="mdi-plus-circle-outline" class="z-index fab raised"
                @click="onAddToCart(item.cartItem.itemId)" />
            </v-col>
            <v-col class="text-body-2 pa-0 ma-0 text-right" cols="auto">
              {{ item.itemTotalPrice }}
            </v-col>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row class="mt-5">
          <v-col class="pa-0 ma-0 me-auto text-subtitle-1 font-weight-bold" cols="auto">{{ $t('total') }}</v-col>
          <v-col class="pa-0 ma-0 text-subtitle-1 font-weight-bold" cols="auto">{{ totalCart() }}</v-col>
        </v-row>
        <v-col class="mt-5 mr-12" style="" :class="{ 'sticky-bottom': stickyCheckoutBtn }" v-if=menu.business.isOpened>
          <router-link :to="{ name: 'checkout', params: { slug: menu.business.slug } }" v-slot="{ navigate }" custom>
            <v-btn @click="navigate" variant="flat" block :disabled=cart.isEmpty() color="primary">
              {{ $t('cart_go_to_checkout') }}
            </v-btn>
          </router-link>
        </v-col>
        <v-col v-else>
          <span class="text-h5 text-error font-weight-bold">{{ $t('closed_message') }}</span>
        </v-col>
      </v-col>
    </v-sheet>
  </v-sheet>
</template>

<script lang='ts'>
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuItemModel, MenuModel } from '@/model/Menu'
import { MenuCartItem } from '@/model/MenuCartItem'
import { MenuCartItemsService } from '@/service/MenuCartItemsService'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    cart: Cart,
    stickyCheckoutBtn: Boolean,
    menu: MenuModel,
    menuModel: MenuModel
  }
})
export default class CartView extends Vue {
  cart: Cart
  menu: MenuModel
  stickyCheckoutBtn: boolean
  menuCartItemsService = new MenuCartItemsService()

  cartItems(): MenuCartItem[] {
    return this.menuCartItemsService.getMenuCartItems(this.cart, this.menu)
  }

  totalCart(): string {
    return this.menuCartItemsService.getCartTotalFormatted(this.cart, this.menu)
  }

  countCart(itemId: string): number {
    return this.cart.getItem(itemId)?.count || 0
  }

  onAddToCart(itemId: string): void {
    this.$store.dispatch('addToCart', new CartSingleItem(itemId, 1))
  }

  onMinusFromCart(itemId: string): void {
    this.$store.dispatch('minusFromCart', itemId)
  }
}
</script>
