<template>
  <ConnexionLayout :done=done :errorMessage=errorMessage>
    <v-form v-model='valid' ref='form' autocomplete="on" validate-on="blur" class="mt-4">
      <v-col>
        <v-text-field v-model='nameInput' :label='$t("account_name")' type="name" autocomplete="given-name" class='mb-1'
          rounded flat variant="solo" bg-color="surface-light" color='blue-grey-darken-4'
          base-color='blue-grey-darken-4' :rules='nameRule()'>
        </v-text-field>
        <v-text-field v-model='emailInput' :label='$t("account_email")' type="email" class='mb-1' rounded flat
          variant="solo" bg-color="surface-light" color='blue-grey-darken-4' base-color='blue-grey-darken-4'
          autocomplete="email" :rules='emailRules()'>
          <template v-slot:prepend-inner>
            <v-icon icon="mdi-email-outline" color='primary' />
          </template>
        </v-text-field>
        <v-text-field v-model='passwordInput' class='mb-1' :label='$t("account_password")' type="password" rounded flat
          variant="solo" bg-color="surface-light" color='blue-grey-darken-4' base-color='blue-grey-darken-4'
          autocomplete="current-password" :rules='passwordRules()'>
          <template v-slot:prepend-inner>
            <v-icon icon="mdi-lock-outline" color='primary' />
          </template>
        </v-text-field>
        <v-btn variant='flat' block @click='submit' color='primary' :disabled=isLoading>
          {{ $t('sign_up') }}
        </v-btn>
      </v-col>
    </v-form>
    <div class="mt-10">
      <p class="text-body">{{ $t('account_have_account') }} <router-link
          :to="{ name: 'login', query: this.$route.query }" class="link">{{ $t('log_in') }}</router-link></p>
    </div>
  </ConnexionLayout>
</template>

<script lang='ts'>
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { userRepository } from '@/data/UserRepository'
import { isEmailValid, isNameValid, isPasswordStrong } from '@/form/rules'
import { SignupEmailExistError } from '@/model/SignupError'
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'

@Options({
  components: { ConnexionLayout }
})
export default class SignupView extends Vue {
  nameInput = ''
  emailInput = ''
  passwordInput = ''
  done = false
  valid = true
  isLoading = false
  errorMessage?: string = null

  nameRule() {
    if (!isNameValid(this.nameInput)) {
      return [this.$t('error_invalid_field')]
    }
    return [true]
  }

  emailRules() {
    if (!isEmailValid(this.emailInput)) {
      return [this.$t('error_invalid_email')]
    }
    return [true]
  }

  passwordRules() {
    if (!this.passwordInput) {
      return [this.$t('error_invalid_field')]
    }
    if (!isPasswordStrong(this.passwordInput)) {
      return [this.$t('error_week_password')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      const redirectUrl = this.$route.query.redirect_url as string
      userRepository.singup(this.nameInput, this.emailInput, this.passwordInput)
        .then(() => {
          this.isLoading = false
          this.$store.dispatch('fetchUserName')
          if (redirectUrl) {
            this.$router.replace(redirectUrl)
          }
          this.done = true
        })
        .catch((e) => {
          this.isLoading = false
          if (e instanceof SignupEmailExistError) {
            this.errorMessage = this.$t('error_email_already_exist')
          } else {
            this.errorMessage = this.$t('error_signup')
          }
        })
    }
  }
}
</script>
