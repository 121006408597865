<template>
  <ConnexionLayout :done=done :errorMessage=errorMessage>
    <template v-slot:top-left>
      <v-btn icon="mdi-close" variant="plain" v-if="canGoBack" @click="goBack" class="ma-3" />
    </template>
    <template #done>
      <div align="center" justify="center" align-self="center" class="pt-10">
        <v-icon icon="mdi-check-circle" size="100" color="success" />
      </div>
      <h3 class="ext-h font-weight-bold pt-8">{{ $t('account_reset_password_done') }}</h3>
    </template>
    <v-form v-model='valid' ref='form' @submit.prevent="submit()" autocomplete="on" validate-on="submit" class="mt-4">
      <v-text-field v-model='emailInput' :label='$t("account_email")' type="email" class='mb-1' rounded flat variant="solo" bg-color="surface-light"
        color='blue-grey-darken-4' base-color='blue-grey-darken-4' autocomplete="email" :rules='emailRules()'>
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-email-outline" color='primary' />
        </template>
      </v-text-field>
      <v-btn variant='flat' block class='mt-2' type='submit' color='primary' :loading=isLoading>
        {{ $t('next') }}
      </v-btn>
    </v-form>
  </ConnexionLayout>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'
import { isEmailValid, isPasswordValid } from '@/form/rules'
import { useI18n } from 'vue-i18n'
import { userRepository } from '@/data/UserRepository'
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { toHandlers } from 'vue'

@Options({
  components: { ConnexionLayout }
})
export default class ForgotPasswordView extends Vue {
  emailInput = ''
  valid = false
  done = false
  passwordVisible = false
  isLoading = false
  errorMessage = ''

  get canGoBack(): boolean {
    return this.$router.options.history.state.back != null
  }

  goBack() {
    this.$router.back()
  }

  emailRules() {
    const { t } = useI18n()
    if (!isEmailValid(this.emailInput)) {
      return [t('error_invalid_email')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      this.errorMessage = ''
      const url = this.$route.query.redirect_url as string
      userRepository.forgetPassword(this.emailInput, url)
        .then(() => {
          this.done = true
        })
        .catch((e) => {
          this.errorMessage = this.$t('error_invalid_credential')
          this.isLoading = false
        })
    }
  }
}
</script>
