<template>
  <v-card class="card-shadow card-padding pa-2 rounded-xl ma-3" color="cardTheme" style="width: 230px;">
    <v-row v-if="showImage">
      <v-col align="center">
        <v-img width="130" height="130" cover :src=item.imageUrl class="rounded-circle" />
      </v-col>
    </v-row>
    <div>
      <v-card-title class="pt-0 pb-0">{{ item.title }}</v-card-title>
      <v-card-text class="text-left pt-1 pb-0 one-line">{{ item.details }}</v-card-text>
      <v-card-subtitle class="pt-1 pb-0 font-weight-bold text-black text-subtitle-1">
        <span :class="{ highlight: item.priceBeforeDiscountFormatted != null }" class="mr-1">{{ item.priceFormatted
        }}</span>
        <span style="text-decoration: line-through">{{ item.priceBeforeDiscountFormatted }}</span>
      </v-card-subtitle>

      <div v-if="countOnCart > 0" class=" mr-1 mt-2 justify-center" color="grey-darken-4">
        <v-btn icon="mdi-delete" color="blue-grey-darken-4" @click.stop="onMinusFromCart()" v-if="countOnCart == 1" />
        <v-btn icon="mdi-minus" color="blue-grey-darken-4" @click.stop="onMinusFromCart()" v-else />
        <span class="align-self-center mr-3 ml-3 text-subtitle-1" color="blue-grey-darken-4"> {{ countOnCart }}</span>
        <v-btn density="default" icon="mdi-plus" color="blue-grey-darken-4" @click.stop="onAddToCart()" />
      </div>
      <div class="mr-1 mt-2 mb-1 justify-center" v-else>
        <v-btn prepend-icon="mdi-plus" class="fab mr-1 mt-2 rounded-pill" color="blue-grey-darken-4"
          @click.stop="onAddToCart()">
          {{ $t('item_add') }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script lang='ts'>
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    item: MenuItemModel,
    countOnCart: Number,
    showImage: Boolean
  }
})
export default class MenuItemCompactView extends Vue {
  item!: MenuItemModel
  countOnCart!: number
  showImage!: boolean

  onAddToCart(): void {
    this.$emit('onAddToCart')
  }

  onMinusFromCart(): void {
    this.$emit('onMinusFromCart')
  }
}
</script>
