<template>
  <ConnexionLayout :done=done :errorMessage=errorMessage>
    <v-form v-model='valid' ref='form' @submit.prevent="submit()" autocomplete="on" validate-on="submit" class="mt-4">
      <v-text-field v-model='emailInput' :label='$t("account_email")' type="email" class='mb-1' rounded flat variant="solo" bg-color="surface-light"
        color='blue-grey-darken-4' base-color='blue-grey-darken-4' autocomplete="email" :rules='emailRules()'>
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-email-outline" color='primary' />
        </template>
      </v-text-field>
      <v-text-field v-model='passwordInput' class='mb-1' :label='$t("account_password")'
        :type="passwordVisible ? 'text' : 'password'" rounded flat variant="solo" bg-color="surface-light" color='blue-grey-darken-4'
        base-color='blue-grey-darken-4' autocomplete="current-password" :rules='passwordRules()'
        @click:append-inner="passwordVisible = !passwordVisible">
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-lock-outline" color='primary' />
        </template>
      </v-text-field>

      <v-btn variant='flat' block class='mt-2' type='submit' color='primary' :loading=isLoading>
        {{ $t('log_in') }}
      </v-btn>
    </v-form>
    <div class="mt-4">
      <v-btn variant='outlined' block class='mt-2' type='submit' color='primary' :disabled=isLoading
        :to="{ name: 'forgot-password', query: this.$route.query }">{{ $t('forgot_password') }}</v-btn>
    </div>
    <div class="mt-10">
      <p class="text-body"> {{ $t('account_no_account') }} <router-link class="link"
          :to="{ name: 'signup', query: this.$route.query }">{{ $t('sign_up')
          }}</router-link></p>
    </div>
  </ConnexionLayout>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'
import { isEmailValid, isPasswordValid } from '@/form/rules'
import { useI18n } from 'vue-i18n'
import { userRepository } from '@/data/UserRepository'
import ConnexionLayout from '@/components/ConnexionLayout.vue'

@Options({
  components: { ConnexionLayout }
})
export default class LoginView extends Vue {
  emailInput = ''
  passwordInput = ''
  valid = false
  done = false
  passwordVisible = false
  isLoading = false
  errorMessage = ''

  emailRules() {
    const { t } = useI18n()
    if (!isEmailValid(this.emailInput)) {
      return [t('error_invalid_email')]
    }
    return [true]
  }

  passwordRules() {
    const { t } = useI18n()
    if (!isPasswordValid(this.passwordInput)) {
      return [t('error_field_required')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      this.errorMessage = ''
      const url = this.$route.query.redirect_url as string
      userRepository.login(this.emailInput, this.passwordInput)
        .then(() => {
          this.$store.dispatch('fetchUserName')
          if (url) {
            this.$router.replace(url)
          }
          this.done = true
        })
        .catch((e) => {
          console.log('Error login', e)
          this.errorMessage = this.$t('error_invalid_credential')
          this.isLoading = false
        })
    }
  }
}
</script>
