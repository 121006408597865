<template>
  <v-col cols="12" lg="6">
    <v-dialog v-model="showDetailsDialog" class="align-center" :fullscreen=showDialogAsFullScreen>
      <template v-slot:activator="{ props }">
        <v-card class="rounded-xl pt-2 pb-2" color="cardTheme" height="100%" v-bind="props">
          <v-row class="d-flex justify-space-between">
            <v-col class="text-left">
              <v-card-title class="pt-0 pb-0">{{ menuSet.title }}</v-card-title>
              <v-card-subtitle class="pt-0 pb-0 font-weight-bold text-black text-subtitle-1">{{
      menuSet.initialPriceFormatted }}</v-card-subtitle>
            </v-col>
            <v-col class="d-flex justify-end">
               <v-img width="150" height="100" aspect-ratio="1/1" cover :src=menuSet.imageUrl />
              <v-btn density="comfortable" icon="mdi-plus" class="z-index fab raised mr-1 mt-1" position="absolute"
                color="blue-grey-darken-4" elevation="9" @click.stop="onAddToCart()" />
            </v-col>
          </v-row>
        </v-card>
      </template>
      <MenuSetDetailsView :menuSet=menuSet :cart=cart :locale=locale class="align-self-center" max-width="800" align="center" @onDismiss="showDetailsDialog = false" />
    </v-dialog>
  </v-col>
</template>

<script lang='ts'>

import { Options, Vue } from 'vue-class-component'
import { MenuSet } from '@/model/Menu'
import { Cart } from '@/model/Cart'
import MenuSetDetailsView from './MenuSetDetailsView.vue'
import { useDisplay } from 'vuetify'

@Options({
  props: {
    menuSet: MenuSet,
    cart: Cart,
    locale: String
  },
  components: { MenuSetDetailsView }
})
export default class MenuSetView extends Vue {
  menuSet!: MenuSet
  cart?: Cart
  showDetailsDialog = false
  locale: string

  onAddToCart() {
    this.showDetailsDialog = true
  }

  get showDialogAsFullScreen(): boolean {
    const { smAndDown } = useDisplay()
    return smAndDown.value
  }
}
</script>
