<template>
  <ConnexionLayout :done=done :errorMessage=errorMessage>
    <template #done>
      <div align="center" justify="center" align-self="center" class="pt-10">
        <v-icon icon="mdi-check-circle" size="100" color="success" />
      </div>
      <v-card-title class="ext-h5 font-weight-bold pt-8">{{ $t('account_passwords_updated') }}</v-card-title>
    </template>
    <v-form v-model='valid' ref='form' @submit.prevent="submit()" autocomplete="on" validate-on="submit" class="mt-4">
      <v-text-field v-model='newPasswordInput' class='mb-1' :label='$t("account_new_password")' type='password'
       color='blue-grey-darken-4' base-color='blue-grey-darken-4' :rules='passwordRules()' rounded flat variant="solo" bg-color="surface-light" >
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-lock-outline" color='primary' />
        </template>
      </v-text-field>
      <v-text-field v-model='confirmNewPasswordInput' class='mb-1' :label='$t("account_confirm_new_password")'
        type='password' color='blue-grey-darken-4' base-color='blue-grey-darken-4'
        :rules='confirmNewpasswordRules()' rounded flat variant="solo" bg-color="surface-light" >
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-lock-outline" color='primary' />
        </template>
      </v-text-field>
      <v-btn variant='flat' block class='mt-2' type='submit' color='primary' :loading=isLoading>
        {{ $t('account_update_password') }}
      </v-btn>
    </v-form>
  </ConnexionLayout>
</template>

<script lang='ts'>
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { userRepository } from '@/data/UserRepository'
import { isPasswordValid } from '@/form/rules'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { VForm } from 'vuetify/lib/components/index.mjs'

@Options({
  components: { ConnexionLayout }
})
export default class ConfirmResetPasswordView extends Vue {
  newPasswordInput = ''
  confirmNewPasswordInput = ''
  valid = false
  done = false
  isLoading = false
  errorMessage?: string = null

  passwordRules() {
    const { t } = useI18n()
    if (!isPasswordValid(this.newPasswordInput)) {
      return [t('error_field_required')]
    }
    return [true]
  }

  confirmNewpasswordRules() {
    const { t } = useI18n()
    if (this.confirmNewPasswordInput !== this.newPasswordInput) {
      return [t('account_passwords_not_match')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      const userId = this.$route.params.u_id as string
      const token = this.$route.params.token as string

      this.isLoading = true
      this.errorMessage = null
      const url = this.$route.query.next as string

      userRepository.confirmResetPassword(this.confirmNewPasswordInput, userId, token)
        .then(() => {
          this.done = true
          this.redirectWithDelay(url)
        })
        .catch((e) => {
          this.errorMessage = this.$t('error_message')
          this.isLoading = false
        })
    }
  }

  redirectWithDelay(redirectUrl: string) {
    if (redirectUrl) {
      this.$router.replace(redirectUrl)
    }
  }
}
</script>
