<template>
  <div>
    <div width="100%">
      <v-img width="100%" max-height="300" aspect-ratio="1/1" cover :src=business.image v-if="mobileDisplay" />
      <v-col v-if="mobileDisplay">
        <v-row class="align-center pt-2">
          <v-col class="text-left">
            <span class="text-h5">{{ business.title }}</span>
          </v-col>
          <v-col cols="auto" class="d-flex align-center">
            <slot name="top-right"></slot>
          </v-col>
        </v-row>
        <v-card-text class="text-subtitle-1 text-left mb-0 pb-0 mt-2">
          <v-btn :href=business.addressUrl target="_blank" variant="text" color="blue-darken-3" class="text-left">
            <v-icon icon="mdi-map-marker-outline pr-4" size="xsmall" />
            <span style="white-space: normal;">
              {{ business.address }}
            </span>
            <v-icon icon="mdi-chevron-right" class="ml-1" />
          </v-btn>
        </v-card-text>
        <blockquote class="text-left" style="white-space: pre-line;" v-if="business.description">
          <span class="text-body-1">{{ business.description }}</span>
        </blockquote>
        <span v-if=business.displayEta>
          <v-card-text class="text-subtitle-1 text-left b-0 pb-0">
            <span v-html="$t('estimated_waiting_time', { time: processingTimeMinutes })" />
          </v-card-text>
        </span>
      </v-col>
      <v-row justify="space-between" v-else>
        <v-row>
          <v-col cols="3" class="pr-12 ">
            <v-img width="100%" height="100%" max-height="300" aspect-ratio="1/1" cover :src=business.image />
          </v-col>
          <v-col cols="7" class="text-left pt-12 pl-0 mb-2">
            <v-card-title class="text-h4 pa-0"> {{ business.title }}</v-card-title>
            <v-card-text class="text-subtitle-1 text-left pl-0 mb-0 pb-0 mt-4">

              <v-btn :href=business.addressUrl target="_blank" variant="plain" color="blue-darken-1">
                <v-icon icon="mdi-map-marker-outline pr-4" size="xsmall" />
                <span style="white-space: normal;">
                  {{ business.address }}
                </span>
                <v-icon icon="mdi-chevron-right" class="ml-1" />
              </v-btn>
            </v-card-text>
            <blockquote class="pa-0 mt-4" style="white-space: pre-line;" v-if="business.description">
              <span class="text-body-1">{{ business.description }}</span>
            </blockquote>

            <span v-if=business.displayEta>
              <v-card-text class="text-body-2 mt-2 pa-0">
                <span v-html="$t('estimated_waiting_time', { time: processingTimeMinutes })" />
              </v-card-text>
            </span>
          </v-col>
        </v-row>
        <v-col cols="auto" class="pt-12 mr-3">
          <slot name="top-right"></slot>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang='ts'>
import { formatDuration } from '@/formatter/DateFormatter'
import { BusinessModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useDisplay } from 'vuetify'

@Options({
  props: {
    business: BusinessModel
  }
})
export default class BusinessView extends Vue {
  business: BusinessModel

  get processingTimeMinutes(): string {
    return formatDuration(this.business.processingTimeMinutes)
  }

  get mobileDisplay(): boolean {
    const { smAndDown } = useDisplay()
    return smAndDown.value
  }
}
</script>
