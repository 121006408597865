import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pl-2 pr-2" }
const _hoisted_2 = { class: "pt-0 pb-0 text-subtitle-1 font-weight-bold" }
const _hoisted_3 = { class: "text-left two-lines text-grey-darken-1" }
const _hoisted_4 = { class: "pt-0 pb-0 font-weight-medium text-black text-subtitle-2 text-grey-darken-3" }
const _hoisted_5 = { style: {"text-decoration":"line-through"} }
const _hoisted_6 = {
  density: "comfortable",
  icon: "mdi-plus",
  class: "align-self-center mr-1 ml-1",
  color: "blue-grey-darken-4",
  elevation: "9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_col, {
    cols: "12",
    lg: "6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "card-padding pt-2 pb-2 rounded-xl",
        theme: "cardTheme",
        height: "100%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "d-flex" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "text-left" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.title), 1),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.details), 1),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", {
                        class: _normalizeClass([{ highlight: _ctx.item.priceBeforeDiscountFormatted != null }, "mr-1"])
                      }, _toDisplayString(_ctx.item.priceFormatted), 3),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.item.priceBeforeDiscountFormatted), 1)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "auto",
                class: "d-flex justify-end",
                style: {"position":"relative"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    width: "150",
                    height: "100",
                    "aspect-ratio": "1/1",
                    cover: "",
                    src: _ctx.item.imageUrl
                  }, null, 8, ["src"]),
                  (_ctx.countOnCart > 0)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        position: "absolute",
                        class: "z-index mr-1 mt-1 bg-blue-grey-darken-4 rounded-pill",
                        elevation: "9",
                        style: {"position":"absolute"},
                        color: "grey-darken-4"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.countOnCart == 1)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                density: "comfortable",
                                icon: "mdi-delete",
                                class: "z-index fab raised",
                                color: "blue-grey-darken-4",
                                elevation: "9",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onMinusFromCart()), ["stop"]))
                              }))
                            : (_openBlock(), _createBlock(_component_v_btn, {
                                key: 1,
                                density: "comfortable",
                                icon: "mdi-minus",
                                class: "z-index fab raised",
                                color: "blue-grey-darken-4",
                                elevation: "9",
                                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onMinusFromCart()), ["stop"]))
                              })),
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.countOnCart), 1),
                          _createVNode(_component_v_btn, {
                            density: "comfortable",
                            icon: "mdi-plus",
                            class: "z-index fab raised",
                            color: "blue-grey-darken-4",
                            elevation: "9",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onAddToCart()), ["stop"]))
                          })
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        density: "comfortable",
                        icon: "mdi-plus",
                        class: "z-index fab raised mr-1 mt-1",
                        position: "absolute",
                        color: "blue-grey-darken-4",
                        elevation: "9",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onAddToCart()), ["stop"]))
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}