<template>
  <v-radio-group v-model="selectedItem" class="menu-set-single-choice-list-view">
    <v-radio
      v-for="item in menuSetItems"
      :key="item.id"
      :value="item"
      class="justify-start"
      v-ripple
    >
      <template v-slot:label>
        <v-row justify="space-between" class="w-100 pl-5 pr-5 font-weight-medium">
          <span class="text-body-1">{{ item.productName }}</span>
          <span class="text-body-1" v-if="item.extraPrice > 0">+{{ item.extraPriceFormatted }}</span>
        </v-row>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script lang='ts'>

import { MenuSetItem } from '@/model/Menu'
import { PropType, defineComponent } from 'vue'
import { VRadioGroup } from 'vuetify/lib/components/index.mjs'

export default defineComponent({
  name: 'MenuSetSingleChoiceListView',
  props: {
    menuSetItems: {
      type: Array as PropType<MenuSetItem[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<MenuSetItem | null>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedItem: this.modelValue,
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedItem = newVal
    },
    selectedItem(newVal) {
      this.$emit('update:modelValue', newVal)
    },
  },
})
</script>
