<template>
  <v-container class="d-flex justify-center mt-10 pb-10 ">
    <v-card width="500" :min-height="500" class="rounded-xl">
      <v-row>
        <slot name="top-left"></slot>
      </v-row>
      <v-col class="pa-4 ma-2 content-center-vetically">
        <slot></slot>
      </v-col>
    </v-card>
  </v-container>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'

@Options({
})
export default class CentralLayout extends Vue {
}
</script>

<style>
.content-center-vetically {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
