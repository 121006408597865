import moment from 'moment'

export function formatDateTime(datetime: Date, userLocale?: string): string {
  const locale = userLocale || navigator.language || 'fr'
  return moment(datetime).locale(locale).format('LLL')
}

export function formatDuration(minutes: number, userLocale?: string): string {
  const locale = userLocale || navigator.language || 'fr'
  const duration = moment.duration(minutes, 'minute').locale(locale).humanize()
  return duration
}

export function formatDurationFromNow(datetime: Date, userLocale?: string): string {
  const locale = userLocale || navigator.language || 'fr'
  const now = moment(new Date())
  const diff = moment(now).diff(datetime)
  const duration = moment.duration(diff).locale(locale).humanize()
  return duration
}

export function formatTime(datetime: Date, userLocale?: string): string {
  const locale = userLocale || navigator.language || 'fr'
  return moment(datetime).locale(locale).format('LT')
}
