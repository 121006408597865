<template>
  <v-checkbox v-for="(item, index) in menuSetItems" :key="item.id" :value="item" v-model="selectedItem[index]" :disabled="!selectedItem[index] && countSelection >= maxSelection"
    hide-details class="justify-start" v-ripple dense>
    <template v-slot:label>
      <v-row justify="space-between" class="w-100 pl-5 pr-5 font-weight-medium pt-0 pb-0 mt-0 mb-0">
        <span class="text-body-1">{{ item.productName }}</span>
        <span class="text-body-1" v-if="item.extraPrice > 0">+{{ item.extraPriceFormatted }}</span>
      </v-row>
    </template>
  </v-checkbox>
</template>

<script lang='ts'>

import { MenuSetItem } from '@/model/Menu'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuSetMultiChoiceListView',
  props: {
    menuSetItems: {
      type: Array as PropType<MenuSetItem[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<MenuSetItem[] | null>,
      required: true,
    },
    minSelection: {
      type: Number,
      required: true,
    },
    maxSelection: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedItem: this.modelValue,
    }
  },
  computed: {
    countSelection() {
      return this.selectedItem.filter(i => i).length
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedItem = newVal
    },
    selectedItem(newVal) {
      this.$emit('update:modelValue', newVal)
    },
  },
})
</script>
