<template>
  <v-container v-if='order' style="text-align: center;">
    <v-col :sm='12' :md='8' :offset-md='2'>
      <v-card class='pa-4 align-self-left justify-left justify-start rounded-xl'>
        <strong class="text-primary text-h1 font-weight-bold">#{{ order.number }}</strong>
        <div class="text-h5">
          {{ $t('order_at_title') }}
          <router-link :to="{ name: 'home', params: { slug: order.business.slug } }" class="link">
            {{ order.business.title }}
          </router-link>
          <div v-if="order.table">
            <v-chip color="primary"> <v-icon icon="mdi-table-furniture" /> {{ order.table }} </v-chip>
          </div>
        </div>
        <v-card-text class="text-center mt-4" v-if="formattedWaitingTime"
          v-html="$t('estimated_waiting_time', { time: formattedWaitingTime })" />
        <v-card-text>
          <v-col class='text-left text-subtitle-1 font-weight-regular m'>
            <v-row v-for='item in order.cart' :key='item.itemId' class='pt-1 pb-1 pl-0 pr-0'>
              <v-col class='pa-0 ma-0'>
                <span>{{ item.count }}x </span>
                <span>{{ item.title }}</span>
              </v-col>
              <v-col class='pa-0 ma-0 text-right'>
                {{ item.totalPrice }}
              </v-col>
            </v-row>
            <v-divider class='mt-8 mb-8'></v-divider>
            <v-row class='pb-1 pl-0 pr-0 text-h6'>
              <v-col class='pa-0 ma-0'>{{ $t('total') }}</v-col>
              <v-col class='pa-0 ma-0 text-right strong'><span class="highlight">{{ order.totalPrice }}</span></v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-text class="text-left" v-if="order.comments">
          <v-icon icon="mdi-comment-outline" /> {{ order.comments }}
        </v-card-text>
        <div class="text-subtitle-1" align="start">
          <div v-if="isCanceled" align="center" class="mt-6">
            <v-icon icon="mdi-cancel" class="mb-1" color="red" size="72" />
            <v-card-text class='text-center text-red text-subtitle-1 font-weight-regular'>
              {{ $t("order_canceled_message") }}
            </v-card-text>
          </div>
          <div v-else-if="isDone" align="center" class="mt-6">
            <v-icon icon="mdi-check-circle" class="mb-1" color="success" size="72" />
            <v-card-text class='text-center text-success text-subtitle-1 font-weight-regular'>
              {{ $t('order_done_message') }}
            </v-card-text>
          </div>
          <v-timeline side="end" align="start" v-else>
            <v-timeline-item dot-color="primary" size="small" icon="mdi-progress-clock"
              :class="{ 'semi-transparent': !isReceived }">
              <strong class="me-4" width="100%">{{ $t('order_received_label') }}</strong>
              <p v-if="isReceived">{{ $t('order_received_message') }}</p>
              <p>{{ formattedOrderDate }}</p>
            </v-timeline-item>
            <v-timeline-item dot-color="primary" size="small" icon="mdi-chef-hat" v-if="showPickedUpStep"
              :class="{ 'semi-transparent': !isPickedUp }">
              <strong class="me-4">{{ $t('order_picked_up_label') }}</strong>
              <p v-if="isPickedUp">{{ $t('order_picked_up_message', { name: order.business.title }) }}
                <span v-if="formattedETATime">{{ $t('order_picked_up_eta_message') }} <strong>{{
                  formattedETATime }}</strong>
                </span>
              </p>
            </v-timeline-item>
            <v-timeline-item dot-color="primary" size="small" icon="mdi-check" v-if="isReady">
              <strong class="me-4">{{ $t('order_ready_label') }}</strong>
              <p>{{ $t('order_ready_message') }}</p>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang='ts'>
import { Order, OrderStatus } from '@/model/OrderCheckout'
import { formatDateTime, formatDurationFromNow, formatTime } from '@/formatter/DateFormatter'
import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { addOnBackgroundNotificationReceived, addOnNotificationReceived } from '@/notification/fcm.notification'
import { SendNotificationTokenService } from '@/service/SendNotificationTokenService'

export default class OrderDetailsView extends Vue {
  get order(): Order | null {
    return this.$store.state.currentOrder
  }

  get formattedOrderDate(): string | null {
    return formatDateTime(this.order.createdAt)
  }

  get formattedWaitingTime(): string | null {
    if (!this.order.business.displayEta || this.order.etaDate == null || this.order.etaDate < new Date()) {
      return null
    }
    return formatDurationFromNow(this.order.etaDate)
  }

  get formattedETATime(): string | null {
    if (!this.order.business.displayEta || this.order.etaDate == null) {
      return null
    }
    return formatTime(this.order.etaDate)
  }

  get isReceived(): boolean {
    return this.order?.status === OrderStatus.received
  }

  get isPickedUp(): boolean {
    return this.order?.status === OrderStatus.inProgress
  }

  get showPickedUpStep(): boolean {
    return this.isPickedUp || this.isReady
  }

  get isReady(): boolean {
    return this.order?.status === OrderStatus.readyToPickup
  }

  get isDone(): boolean {
    return this.order?.status === OrderStatus.done
  }

  get isCanceled(): boolean {
    return this.order?.status === OrderStatus.canceled || this.order?.status === OrderStatus.canceledByUser
  }

  mounted(): void {
    const route = useRoute()
    this.$store.dispatch('fetchOrder', route.params.order_id)
    addOnBackgroundNotificationReceived(() => {
      this.$store.dispatch('fetchOrder', route.params.order_id)
    })
    addOnNotificationReceived(() => {
      this.$store.dispatch('fetchOrder', route.params.order_id)
    })

    this.$watch(() => this.$store.state.currentOrder, (order) => {
      document.title = this.$t('page_order_details_title', { business: order.business.title })
    })

    const sendNotificationTokenService = new SendNotificationTokenService()

    try {
      sendNotificationTokenService.sendNotificationToken()
    } catch (e) {
      console.log(e)
    }
  }
}

</script>
