<template>
  <v-card class="rounded-t-lg">
    <div v-for="order in orders" :key="order.id" class="pl-4 pr-4">
      <router-link :to="{ name: 'order', params: { order_id: order.uuid } }" v-slot="{ navigate }" custom>
        <v-card class="pa-4 ma-4 rounded-xl" @click="navigate">
          <p v-html="orderStatus(order)"></p>
          <v-col cols="12" color="black">
            <v-row v-for='item in order.cart' :key='item.itemId' class='pt-1 pb-1 pl-0 pr-0' justify="space-between">
              <v-col class='pa-0 ma-0'>
                <span>{{ item.count }}x </span>
                <span>{{ item.title }}</span>
              </v-col>
              <v-col class='pa-0 ma-0 text-right'>
                {{ item.totalPrice }}
              </v-col>
            </v-row>
            <v-row class='pb-1 font-weight-bold mt-3' justify="space-between">
              <v-col class='pa-0 ma-0'>{{ $t('total') }}</v-col>
              <v-col class='pa-0 ma-0 text-right strong'>{{ order.totalPrice }}</v-col>
            </v-row>
          </v-col>
        </v-card>
      </router-link>
    </div>
  </v-card>
</template>

<script lang='ts'>
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    orders: Array as PropType<Array<Order>>
  }
})
export default class OrderListView extends Vue {
  orders!: Order[]

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
</script>
