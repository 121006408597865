/* eslint-disable dot-notation */
import { MenuModelDTO } from '@/data/dto/MenuModel.dto'
import { OrderCheckoutDTO, OrderDTO } from '@/data/dto/OrderCheckout.dto'
import axios, { AxiosInstance } from 'axios'
import { userRepository } from '../UserRepository'
import { NotificationTokenDto } from '../dto/AddNotificationToken.dto'
import { ConfirmResetPasswordDTO } from '../dto/ConfirmResetPassword.dto'
import { AnonymousUserDto } from '../dto/CreateAnonymousUser.dto'
import { LoginRequestDTO, LoginResponseDTO } from '../dto/Login.dto'
import { SignupAnonymousUserequestDTO, SignupRequestDTO } from '../dto/Signup.dto'

const apiClient: AxiosInstance = axios.create({
  baseURL: 'https://api.kmandi.com/api/p',
  headers: {
    'Content-type': 'application/json'
  }
})

apiClient.interceptors.request.use(config => {
  const userId = userRepository.getUserId()
  if (userId && !config.headers['Authorization']) {
    config.headers['Authorization'] = userId
  }
  return config
})

export async function getBusinessMenu(id: string): Promise<MenuModelDTO> {
  const dto = (await apiClient.get<MenuModelDTO>(`v1/business/${id}/`)).data
  return dto
}

export async function sendOrder(accessToken: string | null, businessId: number, order: OrderCheckoutDTO): Promise<OrderDTO> {
  const config = accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : null
  const res = (await apiClient.post<OrderDTO>(`/checkout/${businessId}/`, order, config))
  return res.data
}

export async function getOrderDetails(orderId: string): Promise<OrderDTO> {
  const res = (await apiClient.get<OrderDTO>(`/orders/${orderId}/`))
  return res.data
}

export async function getUserOrders(businessSlug: string, onlyTodo: boolean): Promise<OrderDTO[]> {
  const params = {
    only_todo: onlyTodo ? 'true' : null,
    business_slug: businessSlug
  }
  const res = (await apiClient.get<OrderDTO[]>('/orders/', { params: params }))
  return res.data
}

export async function sendNotificationToken(userId: string, token: string) {
  const dto = new NotificationTokenDto(token)
  await apiClient.post<void>(`/user/${userId}/add-notification-token`, dto)
}

export async function createAnonymousUser(captchaToken: string | null): Promise<string> {
  const requestDTO = new SignupAnonymousUserequestDTO(captchaToken)
  const res = await apiClient.post<AnonymousUserDto>('/user/anonymous', requestDTO)
  return res.data.id
}

export async function apiLogin(email: string, password: string): Promise<LoginResponseDTO> {
  const requestDTO = new LoginRequestDTO(email, password)
  const res = await apiClient.post<LoginResponseDTO>('/v1/login/', requestDTO)
  return res.data
}

export async function apiRefreshToken(refreshToken: string): Promise<LoginResponseDTO> {
  const dto = { refresh: refreshToken }
  const res = await apiClient.post<LoginResponseDTO>('/v1/token/refresh/', dto)
  return res.data
}

export async function apiSignup(name: string, email: string, password: string, captchaToken: string): Promise<LoginResponseDTO> {
  const requestDTO = new SignupRequestDTO(name, email, password, captchaToken)
  const res = await apiClient.post<LoginResponseDTO>('/v1/user/', requestDTO)
  return res.data
}

export async function apiForgotPassword(email: string, nextUrl: string | null) {
  const requestDTO = { email: email }
  const params = {
    next: nextUrl
  }
  await apiClient.post('/reset-password/', requestDTO, { params: params })
}

export async function apiConfirmResetPassword(newPassword: string, userId: string, token: string) {
  const dto = new ConfirmResetPasswordDTO(newPassword, userId, token)
  await apiClient.post(`/reset-password/confirm/${userId}/`, dto)
}
