<template>
  <CentralLayout>
    <template v-slot:top-left>
      <slot name="top-left"></slot>
    </template>
    <div v-if="!done" style="width: 100%;" class="pl-3 pr-3">
      <v-alert align="left" icon="mdi-alert-circle-outline" :text=errorMessage type="error" variant="tonal" class="mb-10 mt-5"
        :class="{ invisible: !errorMessage }" />
      <slot></slot>
    </div>
    <v-col v-else style="width: 100%;">
      <slot name="done">
        <div align="center" justify="center" align-self="center">
          <v-img alt="Vue logo" :src="require(`@/assets/img_not_found.png`)" :width="150" :height="150" />
        </div>
        <v-card-title class="ext-h5 font-weight-bold pt-8">{{ $t('you_are_authentifed') }}</v-card-title>
      </slot>
    </v-col>
  </CentralLayout>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'
import CentralLayout from '@/components/CentralLayout.vue'

@Options({
  components: { CentralLayout },
  props: {
    errorMessage: String,
    done: Boolean
  }
})
export default class ConnexionLayout extends Vue {
  done: boolean
  errorMessage: string
}
</script>
