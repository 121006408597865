<template>
  <v-card class="rounded-xl pt-2" elevation="22" width="100%">
    <div class="pl-10 pr-10" style="position: relative">
      <v-img width="100%" max-height="400" aspect-ratio="1/1" cover :src=menuSet.imageUrl v-if="menuSet.imageUrl" />
      <v-btn icon="mdi-close" class="sticky-top ma-2 z-index fab raised rounded-pill" color="blue-grey-darken-4"
        density="comfortable" @click="onDismiss" />
    </div>
    <v-card-title class="pt-15 pb-0 justify-start">{{ menuSet.title }}</v-card-title>

    <v-col class="pl-10 pr-10">
      <div v-for="(section, index) in menuSet.categories" :key="section.id" class="pt-4">
        <v-row class="text-left  text-h6">{{ section.title }}</v-row>
        <v-row class="text-subtitle-2 pb-3 text-disabled" v-if="section.minSelection > 0"
          text-color="red">Obligatoire</v-row>
        <MenuSetMultiChoiceListView :menuSetItems="section.possibleItems" v-model="selectedItemsIndex[index]"
          :minSelection=section.minSelection :maxSelection=section.maxSelection v-if="section.maxSelection > 1" />
        <MenuSetSingleChoiceListView :menuSetItems="section.possibleItems" v-model="selectedItemsIndex[index][0]"
          v-else />
      </div>
    </v-col>

    <div class="sticky-bottom bg-background py-2 px-5">
      <div>
        <v-btn icon="mdi-minus" class="z-index fab" color="blue-grey-darken-4" :disabled="count <= 1" size="small"
          @click.stop="count--" />
        <span class="align-self-center mr-5 ml-5 text-h6" color="blue-grey-darken-4">
          {{ count }}
        </span>
        <v-btn icon="mdi-plus" class="z-index fab" color="blue-grey-darken-4" @click.stop="count++" size="small" />
      </div>
      <v-btn @click="onValidate" variant="flat" block color="primary" :disabled=!allItemSelected class="mt-3"
        size="large">
        {{ $t('menu_set_add_btn', { price: totalPrice }) }}
      </v-btn>
    </div>
  </v-card>
</template>

<script lang='ts'>

import { formatPrice } from '@/data/CurrencyFormatter'
import { Cart, CartMenuSetItem } from '@/model/Cart'
import { MenuSet, MenuSetItem } from '@/model/Menu'
import { v4 as uuidv4 } from 'uuid'
import { Options, Vue } from 'vue-class-component'
import MenuSetMultiChoiceListView from './MenuSetMultiChoiceListView.vue'
import MenuSetSingleChoiceListView from './MenuSetSingleChoiceListView.vue'

@Options({
  props: {
    menuSet: MenuSet,
    cart: Cart,
    locale: String
  },
  components: { MenuSetSingleChoiceListView, MenuSetMultiChoiceListView }
})
export default class MenuSetDetailsView extends Vue {
  menuSet!: MenuSet
  cart?: Cart
  locale: string
  count = 1

  selectedItemsIndex?: MenuSetItem[][] = [[]]

  created(): void {
    this.menuSet.categories.forEach((section, index) => {
      this.selectedItemsIndex[index] = new Array(section.maxSelection).fill(null)
    })
  }

  get allItemSelected(): boolean {
    return !this.selectedItemsIndex.some((selected, index) => {
      const category = this.menuSet.categories[index]
      if (!category) {
        return false
      }
      if (selected.filter(item => item != null).length < category.minSelection) {
        return true
      }
      return false
    })
  }

  onValidate() {
    const itemsId = this.menuContent.map(i => i.id)
    const cartItems = new CartMenuSetItem(uuidv4(), this.menuSet.id, this.count, itemsId)
    this.$store.dispatch('addToCart', cartItems)
    this.onDismiss()
  }

  get totalPrice(): string {
    const price = this.menuContent.reduce((sum, item) => item.extraPrice + sum, this.menuSet.initialPrice)
    return formatPrice(price, this.locale)
  }

  get menuContent(): MenuSetItem[] {
    return this.selectedItemsIndex.flatMap(i => i.filter(j => j))
  }

  onDismiss(): void {
    this.$emit('onDismiss')
  }
}
</script>
