import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  align: "center",
  justify: "center",
  "align-self": "center",
  class: "pt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ConnexionLayout = _resolveComponent("ConnexionLayout")!

  return (_openBlock(), _createBlock(_component_ConnexionLayout, {
    done: _ctx.done,
    errorMessage: _ctx.errorMessage
  }, {
    done: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, {
          icon: "mdi-check-circle",
          size: "100",
          color: "success"
        })
      ]),
      _createVNode(_component_v_card_title, { class: "ext-h5 font-weight-bold pt-8" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('account_passwords_updated')), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
        ref: "form",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
        autocomplete: "on",
        "validate-on": "submit",
        class: "mt-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.newPasswordInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPasswordInput) = $event)),
            class: "mb-1",
            label: _ctx.$t("account_new_password"),
            type: "password",
            color: "blue-grey-darken-4",
            "base-color": "blue-grey-darken-4",
            rules: _ctx.passwordRules(),
            rounded: "",
            flat: "",
            variant: "solo",
            "bg-color": "surface-light"
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-lock-outline",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.confirmNewPasswordInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmNewPasswordInput) = $event)),
            class: "mb-1",
            label: _ctx.$t("account_confirm_new_password"),
            type: "password",
            color: "blue-grey-darken-4",
            "base-color": "blue-grey-darken-4",
            rules: _ctx.confirmNewpasswordRules(),
            rounded: "",
            flat: "",
            variant: "solo",
            "bg-color": "surface-light"
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-lock-outline",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_v_btn, {
            variant: "flat",
            block: "",
            class: "mt-2",
            type: "submit",
            color: "primary",
            loading: _ctx.isLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('account_update_password')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["done", "errorMessage"]))
}