<template>
  <v-card class="rounded-xl mx-auto" color="primary" v-if="!cart.isEmpty()" :dark="true" elevation="12">
    <v-row class="pa-3 space-between">
      <v-col class="text-subtitle-1 font-weight-bold" cols="auto">{{ cart.getCountItems() }}</v-col>
      <v-col class="align-self-center text-subtitle-1 font-weight-bold">{{ $t('cart_view') }}</v-col>
      <v-col class="text-subtitle-1 font-weight-bold" cols="auto">{{ totalCart() }}</v-col>
    </v-row>
  </v-card>
</template>

<script lang='ts'>
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuItemModel, MenuModel } from '@/model/Menu'
import { MenuCartItem } from '@/model/MenuCartItem'
import { MenuCartItemsService } from '@/service/MenuCartItemsService'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    cart: Cart,
    menu: MenuModel,
    menuModel: MenuModel
  }
})
export default class CartBannerView extends Vue {
  cart: Cart
  menu: MenuModel
  menuCartItemsService = new MenuCartItemsService()

  cartItems(): MenuCartItem[] {
    return this.menuCartItemsService.getMenuCartItems(this.cart, this.menu)
  }

  totalCart(): string {
    return this.menuCartItemsService.getCartTotalFormatted(this.cart, this.menu)
  }

  countCart(itemId: string): number {
    return this.cart.getItem(itemId)?.count || 0
  }

  onAddToCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('addToCart', new CartSingleItem(menuItem.id, 1))
  }

  onMinusFromCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('minusFromCart', menuItem.id)
  }
}
</script>
