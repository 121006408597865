<template>
  <v-col cols="12" lg="6">
    <v-card class="card-padding pt-2 pb-2 rounded-xl" theme="cardTheme" height="100%">
      <v-row class="d-flex">
        <v-col class="text-left">
          <div class="pl-2 pr-2">
            <span class="pt-0 pb-0 text-subtitle-1 font-weight-bold">{{ item.title }}</span>
            <div class="text-left two-lines text-grey-darken-1">{{ item.details }}</div>
            <div class="pt-0 pb-0 font-weight-medium text-black text-subtitle-2 text-grey-darken-3">
              <span :class="{ highlight: item.priceBeforeDiscountFormatted != null }" class="mr-1">
                {{ item.priceFormatted }}
              </span>
              <span style="text-decoration: line-through">{{ item.priceBeforeDiscountFormatted }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="d-flex justify-end" style="position: relative;">
          <v-img width="150" height="100" aspect-ratio="1/1" cover :src=item.imageUrl />
          <v-row v-if="countOnCart > 0" position="absolute" class="z-index mr-1 mt-1 bg-blue-grey-darken-4 rounded-pill"
            elevation="9" style="position: absolute;" color="grey-darken-4">
            <v-btn density="comfortable" icon="mdi-delete" class="z-index fab raised" color="blue-grey-darken-4"
              elevation="9" @click.stop="onMinusFromCart()" v-if="countOnCart == 1" />
            <v-btn density="comfortable" icon="mdi-minus" class="z-index fab raised" color="blue-grey-darken-4"
              elevation="9" @click.stop="onMinusFromCart()" v-else />
            <span density="comfortable" icon="mdi-plus" class="align-self-center mr-1 ml-1" color="blue-grey-darken-4"
              elevation="9">
              {{ countOnCart }}
            </span>
            <v-btn density="comfortable" icon="mdi-plus" class="z-index fab raised" color="blue-grey-darken-4"
              elevation="9" @click.stop="onAddToCart()" />
          </v-row>
          <v-btn density="comfortable" icon="mdi-plus" class="z-index fab raised mr-1 mt-1" position="absolute"
            color="blue-grey-darken-4" elevation="9" @click.stop="onAddToCart()" v-else />
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script lang='ts'>
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    item: MenuItemModel,
    countOnCart: Number
  }
})
export default class MenuItemStandardView extends Vue {
  item!: MenuItemModel
  countOnCart!: number

  onAddToCart(): void {
    this.$emit('onAddToCart')
  }

  onMinusFromCart(): void {
    this.$emit('onMinusFromCart')
  }
}
</script>
