<template>
  <v-bottom-sheet v-if="orders.length > 0" v-model="displayOrdersDialog" inset>
    <template v-slot:activator="{ props }">

      <router-link :to="{ name: 'order', params: { order_id: orders[0].uuid } }" v-slot="{ navigate }" custom
        v-if="orders.length == 1">
        <v-card color="primary" class="mt-2 rounded-xl" @click="navigate" v-bind="props">
          <v-card-text class="text-left text-subtitle-1 font-weight-bold">
            <span v-html="orderStatus(orders[0])"></span>
          </v-card-text>
        </v-card>
      </router-link>
      <v-card color="primary rounded-xl" class="mt-2" v-else-if="orders.length > 0"
        @click="setDisplayOrdersDialog(true)" v-bind="props">
        <v-card-text class="text-left text-subtitle-1 font-weight-bold">
          {{ $t('order_count_ongoing_orders', { count: orders.length }) }}
        </v-card-text>
      </v-card>
    </template>
    <OrderListView :orders=orders />
  </v-bottom-sheet>
</template>

<script lang='ts'>
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { Options, Vue } from 'vue-class-component'
import OrderListView from './OrderListView.vue'

@Options({
  props: {},
  components: { OrderListView }
})
export default class OngoingOrdersView extends Vue {
  displayOrdersDialog = false

  setDisplayOrdersDialog(show: boolean): void {
    this.displayOrdersDialog = show
  }

  get orders(): Order[] {
    return this.$store.state.ongoingOrders ?? []
  }

  mounted(): void {
    this.$store.dispatch('fetchOngoingOrders', this.$store.state.menu.business.slug)
  }

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
</script>
